
.email_main {
    flex-grow: 1;
    height: 100%;
    background-color: white;
    transition: margin-right 0.2s ease;
    width: calc(100% - 259px);

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    &.overlay {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(75,70,92,.5);
            z-index: 3;
            transition: all .25s ease;
            content: '';
        }
    }
}

.email_search {
    padding: 16px 22px;
    border-bottom: 1px solid #EBE9F1;
    display: flex;
    align-items: center;
}

.email_search .image {
    display: none;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        display: block;
        margin-right: 16px;
    }
}

.email_search .image svg {
    fill: #7367F0;
}

.email_search svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.email_search input {
    flex-grow: 1;
}

.email_search input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.email_search input::placeholder {
    color: #BABFC7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.email_buttons_block {
    padding: 12px 20px;
    border-bottom: 1px solid #EBE9F1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email_buttons_block label {
    // user-select: none;
    // cursor: pointer;
    width: 102px;
    text-align: right;
}

.email_buttons_block input {
    // cursor: pointer;
    // width: 0;
    // height: 0;
    // position: relative;
    margin-right: 30px;
}

.email_buttons_block i {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.email_buttons {
    display: flex;
    gap: 14px;
    align-items: center;

    li {
        cursor: pointer;
    }
}

.email_message {
    border-bottom: 1px solid #EBE9F1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;

    &.email_message_checked {
        background: rgba(115, 103, 240, 0.06);
    }

    &.unreaded {
        background: rgba(75,70,92,.04);
    }
}

.email_info {
    display: flex;
    align-items: center;
    width: 100%;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        align-items: center;
        justify-content: center;
        color: #28C76F;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #E5F8EE;
        margin-right: 14px;
    }
}

// .email_info img {
//     width: 38px;
//     height: 38px;
//     border-radius: 38px;
//     margin-right: 14px;
// }

.email_data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.email_data span:first-child {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.email_data span:last-child {
    color: #82868B;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.email_additional {
    display: flex;
    align-items: center;
    gap: 6px;
}

.email_type {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    &.personal {
        background-color: #28C76F;
    }
    &.company {
        background-color: #7367F0;
    }
    &.important {
        background-color: #FF9F43;
    }
    &.private {
        background-color: #EA5455;
    }
}



.email_datetime {
    color: #B9B9C3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.email_content {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

// .email_content input {
//     width: 0;
//     height: 0;
// }

// .email_content input:before {
//     bottom: 10px;
// }

// .email_content input:after {
//     bottom: 23px;
// }

.email_content p {
    color: #BABFC7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.avatar_empty {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    border-radius: 38px;
    color: #7367F0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #EEEDFD;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
}

.close_button_search svg{
    width: 21px;
    height: 21px;
    margin: 0;
    cursor: pointer;
}

.email_list {
    overflow-y: auto;
    height: calc(100% - 100px);
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.email_list::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}
.unreadedNotify {
   border-right:7px solid #EA5455 ;
    border-radius: 3px;
}