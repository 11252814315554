
.email_menu_block {
    width: 259px;
    min-width: 259px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-right: 1px solid #EBE9F1;
    transition: margin-left 0.2s ease;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 16px;

    @media screen and (max-width: 1024px) {
        position: absolute;
        z-index: 10;
    }

    .new_email {
        max-width: 228px;
        margin: 0 auto;
    }
}

.email_menu_block::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.email_menu_block_hide {

    @media screen and (max-width: 1024px) {
        margin-left: -100% !important;
    }
}

.email_menu_block .button {
    margin: 16px;
    width: initial;
}
.email_menu {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 33px;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    margin-top: 26px;
}

.email_menu li {
    width: 100%;
    height: 38px;
}

.email_menu li .email_type_button {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.email_menu li .active {
    border-left: 3px solid #7367F0;
}

.email_menu li .active .image {
    margin-left: 17px;
}

.email_menu li .active svg {
    fill: #B9B2F7;
}

.email_menu li .active span {
    color: #7367F0;
}

.email_menu li .email_type_button .image {
    margin-left: 20px;
    margin-right: 12px;
}

.email_menu li .email_type_button span {
    flex-grow: 1;
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.messages_count {
    margin-right: 20px;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
}

.messages_count_inbox {
    background: rgba(115, 103, 240, 0.12);
    color: #7367F0;
}

.messages_count_draft {
    background: rgba(246, 144, 176, 0.12);
    color: #F690B0;
}

.messages_count_spam {
    background: rgba(227, 171, 114, 0.12);
    color: #E3AB72;
}

.email_labels h4 {
    color: #BABFC7;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.email_labels ul {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.email_labels ul li {
    position: relative;
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    cursor: pointer;
    display: flex;
    gap: 12px;
    align-items: center;
}

.email_labels ul li.active {
    color: #7367F0;
}

.email_labels ul li div {
    min-width: 10px;
    min-height: 10px;
    max-width: 10px;
    max-height: 10px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-bottom: 3px;
}

.email_labels ul li .label-green {
    background-color: #28C76F;
}

.email_labels ul li .label-violet {
    background-color: #7367F0;
}

.email_labels ul li .label-orange {
    background-color: #FF9F43;
}

.email_labels ul li .label-red {
    background-color: #EA5455;
}
