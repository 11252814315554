.chat_field {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: margin-right 0.2s ease;

    @media screen and (max-width: 1024px) {
        height: 100%;
        width: 100%;
        margin-right: -100%;
    }

    .scroll-container {
        display: flex;
        flex-direction: column;
        height: calc(100% - 68px - 68px);
        overflow-y: scroll;
        scrollbar-width: none;
    }
}

.chat_field_active {
    @media screen and (max-width: 1024px) {
        height: 100%;
        width: 100%;
        margin-right: 0 !important;
    }
}

.chat_header {
    padding: 12px 18px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.chat_users {
    .image {
        svg {
            fill: #7367F0;
        }
    }
}

.user_message {
    img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #7367F0;
    }
}

.chat_users {
    display: flex;
    align-items: center;
    gap: 25px;

    .image {
        display: none;
        margin-left: 14px;
        transform: rotate(90deg);

        @media screen and (max-width: 1024px) {
            display: block;
        }
    }
}

.chat_user {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 18px;

    &>img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    &:before {
        left: 28px !important;
        bottom: 0 !important;
    }

    &>span {
        color: #393939;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
    }

    .avatar  {
        width: 40px;
        height: 40px;
        margin: 0;
        color: #fff;
        text-transform: capitalize;
    }
}

.chat_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &>.image {
        cursor: pointer;
    }
}

.chat-video {
    margin-right: 2px;

    svg {
        margin-right: 2px;
        width: 18px;
        height: 16px;
    }
}

.chat_messages {
    background-color: #F2F0F6;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }
}

.user_message_filler {
    flex-grow: 1;
}

.chat_date_separator {
    width: 100%;
    height: 1px;
    background-color: #EBE9F1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    span {
        color: #6E6B7B;
        background-color: white;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        padding: 1px 12px;
        border-radius: 5px;
    }
}

.user_message {
    display: flex;
    width: 60%;
    gap: 12px;
    align-items: flex-start;
    align-self: flex-start;

    @media screen and (max-width: 744px) {
        width: calc(100% - 52px);
    }

    div {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 40px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.56px;
        text-transform: capitalize;
        background-color: #7367F0;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;
        align-items: flex-start;
        width: calc(100% - 50px);
    }

    li {
        padding: 10px 12px;
        color: #6E6B7B;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        border-radius: 5px;
        background-color: white;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
    }
}

.self_user {
    flex-direction: row-reverse;
    align-self: flex-end;

    ul {
        align-self: flex-end;
        align-items: flex-end;
    }

    li {
        color: white;
        background: linear-gradient(47deg, #7367F0 0%, #9E95F5 100%);
    }
}

.chat_input_block {
    background-color: white;
    padding: 12px 18px;
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
}

.chat_input {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #EBE9F1;
    background-color: white;

    .image {
        cursor: pointer;
        margin-top: 3px;
    }
}

.chat_messages_input {
    color: #6E6B7B;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 20px;
    max-height: 72px;
    flex-grow: 1;
    resize: none;
    line-height: 16px;
    padding: 2px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }

    &::placeholder {
        color: #B9B9C3;
    }
}

.chat_status_online {
    &:before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: 12px;
        left: 46px;
        border: 2px solid white;
        background-color: rgba(40, 199, 111, 1);
        z-index: 6;
    }
}

.chat_status_offline {
    &:before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: 12px;
        left: 46px;
        border: 2px solid white;
        background-color: rgba(130, 134, 139, 1);
        z-index: 6;
    }
}

.chat_status_empty {
    &:before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: 12px;
        left: 46px;
        border: 2px solid white;
        background-color: rgba(255, 159, 67, 1);
        z-index: 6;
    }
}

.chat_status_busy {
    &:before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: 12px;
        left: 46px;
        border: 2px solid white;
        background-color: rgba(234, 84, 85, 1);
        z-index: 6;
    }
}

.chat_status_waiting {
    &:before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        border-radius: 50%;
        bottom: 12px;
        left: 46px;
        border: 2px solid white;
        background-color: rgba(246, 144, 176, 1);
        z-index: 6;
    }
}