
.email_letters_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
}

.email_letters_list_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    background-color: white;
    border-bottom: 1px solid #EBE9F1;
}

.email_letters_list_header .image {
    cursor: pointer;
}

.email_letters_list_header_left_block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
}

.email_letters_list_header_left_block span {
    color: #393939;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.email_letters_list_header_left_block .image,
.email_letters_list_header_left_block svg {
    height: 16px;
    width: 16px;
    fill: rgba(110, 107, 123, 1);
}

.email_letters_list_header_right_block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.email_letters_list_header_right_block svg {
    fill: rgba(110, 107, 123, 1);
}

.email_letters_list_header_right_block .icon-email {
    height: 16px;
}

.button_separator {
    width: 1px;
    height: 22px;
    background-color: #EBE9F1;
}

.arrow-rotate {
    transform: rotate(180deg);
}

.arrow-email,
.arrow-email svg{
    width: 16px;
    height: 16px;
}

.email_letters_list_area {
    padding: 25px 0 25px 25px;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    flex-grow: 1;
    gap: 25px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media screen and (max-width: 1024px) {
        padding-right: 25px;
    }
}

.email_letters_list_area::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.email_letters_list_letter {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.email_letters_list_letter_header {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EBE9F1;
}

.email_letters_list_letter_header_personal {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;

    .avatar {
        width: 48px;
        height: 48px;
        margin: 0;
        color: #fff;
        text-transform: capitalize;
        font-size: 20px;
    }
}

.email_letters_list_letter_header_personal .name_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .name {
        color: #393939;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
    }

    .date {
        color: #BABFC7;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }
}

// .email_letters_list_letter_header_personal div span:first-child {
    
// }

// .email_letters_list_letter_header_personal div span:last-child {
//     color: #BABFC7;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 21px;
// }

.email_letters_list_letter_date {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.email_letters_list_letter_date span {
    color: #BABFC7;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.email_letters_list_letter_date .image {
    cursor: pointer;
}

.email_letters_list_letter_body {
    padding: 28px 24px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.email_letters_list_letter_body p {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    word-break: break-all;
}

.emails_letter_attachments {
    padding: 12px 24px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    border-top: 1px solid #EBE9F1;
}

.emails_letter_attachments span {
    color: #6E6B7B;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.email_letters_list_footer {
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
    padding: 19px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    margin-left: 25px;

    @media screen and (max-width: 1024px) {
        margin-right: 25px;
        margin-bottom: 25px;
    }
}

.email_letters_list_footer span {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}
.email_letters_list_footer p {
    color: #7367F0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-decoration: underline;
    cursor: pointer;
}