.chat {

    &.chat_notification_open {
        margin-top: 0 !important;
    }

    .chat_block {
        margin-left: 24px;
        margin-right: 24px;
        height: calc(100vh - 124px - 2px);
        border-radius: 6px;
        display: flex;
        overflow: hidden;
        gap: 24px;
    
        @media screen and (max-width: 1024px) {
            border-radius: 0;
            margin-left: 0;
            margin-right: 0;
            height: calc(100vh - 76px - 61px - 2px);
        }

        @media screen and (max-width: 744px) {
            height: calc(100vh - 57px - 76px - 2px);
        }
    }

    .no-ladies {
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            font-size: 28px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

