.chat_field {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: margin-right 0.2s ease;

    @media screen and (max-width: 1024px) {
        height: 100%;
        width: 100%;
        margin-right: -100%;
    }

    .scroll-container {
        display: flex;
        flex-direction: column;
        height: calc(100% - 68px - 68px);
        overflow-y: scroll;
        scrollbar-width: none;
    }
}

.chat_field_active {
    @media screen and (max-width: 1024px) {
        height: 100%;
        width: 100%;
        margin-right: 0 !important;
    }
}

.video_chat_header {
    width: 100%;
    border-radius: 6px;
    background: #FFF;

    @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 0;
    }
}


.video_chat_header_chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 16px;
}

.video_chat_header_chat > div {
    display: flex;
    align-items: center;
    gap: 16px;
}

.video_chat_header_chat .image {
    display: none;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        display: block;
    }

    svg {
        color: #7367F0;
        fill: #7367F0;
        font-size: 16px;
    }
}

.chat_user {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 18px;

    &>img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    &:before {
        left: 28px !important;
        bottom: 0 !important;
    }

    &>span {
        color: #393939;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
    }

    .avatar  {
        width: 40px;
        height: 40px;
        margin: 0;
        color: #fff;
        text-transform: capitalize;
    }
}


.video_chat_main_area {
    flex-grow: 1;
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
        padding: 0 24px;
    }
}

.video_chat_cards {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;

    @media screen and (max-width: 1024px) {
        gap: 24px;
    }

    @media screen and (max-width: 744px) {
        gap: 20px;
    }
}

.video_chat_cards > div {
    max-width: 500px;
    max-height: 280px;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content:space-around ;
}

.video_chat_cards > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.video_chat_footer {
    padding: 12px 21px;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        margin: 0;
    }

    .chat_input_block {
        width: 100%;
        background-color: white;
        display: flex;

        gap: 20px;
        align-items: center;
        justify-content: center;
    }
    
    .chat_input {
        flex-grow: 1;
        display: flex;

        align-items: flex-start;
        justify-content: center;
        gap: 12px;
        padding: 8px 16px;
        border-radius: 5px;
        border: 1px solid #EBE9F1;
        background-color: white;
    
        .image {
            cursor: pointer;
            margin-top: 3px;
        }
    }
    
    .chat_messages_input {
        color: #6E6B7B;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 20px;
        max-height: 72px;
        flex-grow: 1;
        resize: none;
        line-height: 16px;
        padding: 2px;
        scrollbar-width: none;
        -ms-overflow-style: none;
    
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
    
        &::placeholder {
            color: #B9B9C3;
        }
    }
}

.chat_messages {
    margin-top: 40px;
    padding: 20px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: auto;

    @media screen and (max-width: 1024px) {
        padding: 16px;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }

    .user_message {
        img {
            min-width: 40px;
            height: 40px;
            width: 40px;
            border-radius: 40px;
            background-color: #7367F0;
        }
    }

    .user_message_filler {
        flex-grow: 1;
    }

    .user_message {
        display: flex;

        width: 60%;
        gap: 12px;
        align-items: flex-start;
        align-self: flex-start;
    
        @media screen and (max-width: 744px) {
            width: calc(100% - 52px);
        }
    
        div {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 40px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0.56px;
            text-transform: capitalize;
            background-color: #7367F0;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex-grow: 1;
            align-items: flex-start;
        }
    
        li {
            padding: 10px 12px;
            color: #6E6B7B;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            border-radius: 5px;
            background-color: white;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
        }
    }

    .self_user {
        flex-direction: row-reverse;
        align-self: flex-end;
    
        ul {
            align-self: flex-end;
            align-items: flex-end;
        }
    
        li {
            color: white;
            background: linear-gradient(47deg, #7367F0 0%, #9E95F5 100%);
        }
    }
    
}

.chat_date_separator {
    width: 100%;
    height: 1px;
    background-color: #EBE9F1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    span {
        color: #6E6B7B;
        background-color: white;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        padding: 1px 12px;
        border-radius: 5px;
    }
}