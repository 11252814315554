.scroll-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 68px - 68px);
    overflow-y: scroll;
    scrollbar-width: none;

    .chat_messages {
        background-color: #F2F0F6;
        padding: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 20px;
    
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }

        &.videochat {
            background: transparent;
            padding: 20px 0;
        }
    }

    .user_message_filler {
        flex-grow: 1;
    }

    .chat_date_separator {
        width: 100%;
        height: 1px;
        background-color: #EBE9F1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    
        span {
            color: #6E6B7B;
            background-color: white;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            padding: 1px 12px;
            border-radius: 5px;
        }
    }

    .user_message {
        img {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background-color: #7367F0;
        }

        display: flex;
        width: 60%;
        gap: 12px;
        align-items: flex-start;
        align-self: flex-start;

        @media screen and (max-width: 744px) {
            width: calc(100% - 52px);
        }

        div {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 40px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0.56px;
            text-transform: capitalize;
            background-color: #7367F0;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
            display: flex;
            align-items: center;
            justify-content: center;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex-grow: 1;
            align-items: flex-start;
            width: calc(100% - 50px);
        }

        li {
            padding: 10px 12px;
            color: #6E6B7B;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            border-radius: 5px;
            background-color: white;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
            word-break: break-all;
        }

        &.self_user {
            flex-direction: row-reverse;
            align-self: flex-end;
        
            ul {
                align-self: flex-end;
                align-items: flex-end;
            }
        
            li {
                color: white;
                background: linear-gradient(47deg, #7367F0 0%, #9E95F5 100%);
            }
        }
    }
}