.empty_chat_block {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 28px;

    img {
        max-width: 250px;
    }

    p {
        color: #7367F0;
        font-family: "Sora";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.4px;
        text-transform: capitalize;
    }
}