.email_letters_reply {
    flex-grow: 1;
    width: 100%;
    bottom: 0;
    /*max-height: calc(100% - 25px);*/

    @media screen and (max-width: 1024px) {
        /*right: 25px;*/
        /*bottom: 25px;*/
    }
}

.emails_reply {
    padding: 24px 0 24px 24px;
    flex-grow: 1;

    @media screen and (max-width: 1024px) {
        padding: 24px;
    }
}


.email_letters_list_letter {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.email_letters_list_letter_header {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EBE9F1;
}

.email_letters_list_letter_header_personal {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;

    .avatar {
        width: 48px;
        height: 48px;
        margin: 0;
        color: #fff;
        text-transform: capitalize;
        font-size: 20px;
    }
}

.email_letters_list_letter_header_personal .name_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .name {
        color: #393939;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
    }

    .date {
        color: #BABFC7;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }
}

// .email_letters_list_letter_header_personal div span:first-child {
//     color: #393939;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 21px;
// }

// .email_letters_list_letter_header_personal div span:last-child {
//     color: #BABFC7;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 21px;
// }

.email_letters_list_letter_date {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.email_letters_list_letter_date span {
    color: #BABFC7;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.email_letters_list_letter_date .image {
    cursor: pointer;
}

.email_letters_list_letter_body {
    padding: 28px 24px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.email_letters_list_letter_body p {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    word-break: break-all;
}