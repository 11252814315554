
.email_window {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 600px;
    height: 500px;
    background-color: white;
    border-radius: 6px;
    box-shadow: -4px 5px 60px 0 rgba(0, 0, 0, 0.14);
    display: flex;
    flex-direction: column;
    z-index: 6;

    @media screen and (max-width: 1024px) {
        width: 100%;

    }

    &.in_reply {
        position: initial;
        width: 100%;
        height: 100%;
        box-shadow: none;
    }
}

.email_window_full {
    width: calc(100% - 259px) !important;

    @media screen and (max-width: 1024px) {
        width: 100% !important;
    }
}

.maximize_button {
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.email_window_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 21px;
    background-color: #F8F8F8;
}

.email_window_header h5,
.email_window_to h5,
.email_window_subject h5 {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.email_window_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.email_window_buttons .image svg {
    cursor: pointer;
}

.email_window_to {
    padding: 6px 21px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #F8F8F8;
}

.email_window_to input {
    flex-grow: 1;
}

.email_window_to_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.email_window_pill {
    padding: 3px 6px;
    border-radius: 3px;
    background-color: #7367F0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.email_window_pill span {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    user-select: none;
}

.email_window_pill svg {
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.email_window_pill .image {
    width: 12px;
    height: 12px;
}

.email_window_subject {
    padding: 8px 21px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #F8F8F8;
}

.email_window_subject input {
    flex-grow: 1;
}

.email_window_body {
    padding: 10px 21px;
    flex-grow: 1;
    border-bottom: 1px solid #F8F8F8;
}

.email_window_body textarea {
    resize: none;
    width: 100%;
    height: 100%;
}

.email_window_editor {
    /*padding: 10px 21px;*/
    border-bottom: 1px solid #F8F8F8;
}

.email_window_footer {
    padding: 12px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email_window_left_buttons,
.email_window_right_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.email_window_left_buttons button {
    padding: 10px 22px;
}

.email_window_left_buttons {
    .file-name {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
            color: #82868B;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }

        .remove-file {
            cursor: pointer;
        }
    }
}

.add_file,
.more_info,
.delete_message{
    cursor: pointer;
}

.added_file_list_new,
.added_file_list_reply {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
}

.added_file_list_new div,
.added_file_list_reply div {
    display: flex;
    gap: 2px;
    align-items: center;
}

.added_file_list_new div span,
.added_file_list_reply div span {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
}

.added_file_list_new div svg,
.added_file_list_reply div svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.minimize_email {
    height: 54px !important;
    overflow: hidden;
}
