.email {
    .email_block {
        position: relative;
        margin: 0 24px;
        display: flex;
        border-radius: 6px;
        border: 1px solid #EBE9F1;
        height: calc(100vh - 124px);
        overflow: hidden;
    
        @media screen and (max-width: 1024px) {
            height: calc(100vh - 61px - 106px);
            margin: 0;
        }
    
        @media screen and (max-width: 744px) {
            height: calc(100vh - 57px - 106px);
        }
    }
    
    .email_block_maximize_email_window {
        position: relative;
    }

    .no-ladies {
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            font-size: 28px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
