
.chat_contacts_list {
    width: 360px;
    min-width: 360px;
    border-right: 1px solid #EBE9F1;
    background-color: white;
    overflow: hidden;
    /*height: calc(100vh - 92px);*/
    height: 100%;
    margin-left: 0;
    transition: margin-left 0.2s ease;

    @media screen and (max-width: 1024px) {
        /*height: calc(100vh - 131px);*/
        height: 100%;
        width: 100%;
        margin-left: -100%;
    }
}

.newMessage{
    background-color: #28C76F;
     border: 2px solid red;
}

.chat_contacts_list_active {
    @media screen and (max-width: 1024px) {
        margin-left: 0 !important;
    }
}

.chat_search_block {
    padding: 15px;
    border-bottom: 1px solid #EBE9F1;
    /*margin-bottom: 18px;*/
}

.chat_search_input {
    width: 100%;
    border-radius: 19px;
    border: 1px solid #EBE9F1;
    height: 38px;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.chat_search_input input {
    flex-grow: 1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    color: #6E6B7B;
}

.chat_search_input input::placeholder {
    color: #B9B9C3;
}
.chat_search_input .image {
    margin-right: 8px;
}
.chat_search_input .image svg {
    width: 16px;
    height: 16px;
}

.chat_search_close_button {
    cursor: pointer;
}

.chat_chats_block,
.chat_contacts_block {
    margin-bottom: 30px;
    width: 100%;
    padding: 0 !important;
    overflow: hidden;
}

.chat_chats_block h4,
.chat_contacts_block h4 {
    color: #7367F0;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    padding: 0 18px;
}

.chat_list li {
    padding: 12px 18px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 14px;
    cursor: pointer;
    position: relative;
}

.chat_list > .active {
    background: linear-gradient(47deg, #7367F0 0%, #9E95F5 100%) !important;
}

.chat_list li:hover {
    background-color: #F8F8F8;;
}

.chat_list li > img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.chat_list li > .avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    color: #28C76F;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #E5F8EE;
    margin: 0;
}

.chat_list .active > img {
    border: 2px solid white;
}

.chat_list li > .name {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    width: calc(100% - 40px - 28px - 42px);
}

.chat_status_online:before {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    border-radius: 50%;
    bottom: 12px;
    left: 46px;
    border: 2px solid white;
    background-color: rgba(40, 199, 111, 1);
    z-index: 6;
}
.chat_status_offline:before {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    border-radius: 50%;
    bottom: 12px;
    left: 46px;
    border: 2px solid white;
    background-color: rgba(130, 134, 139, 1);
    z-index: 6;
}
.chat_status_empty:before {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    border-radius: 50%;
    bottom: 12px;
    left: 46px;
    border: 2px solid white;
    background-color: rgba(255, 159, 67, 1);
    z-index: 6;
}
.chat_status_busy:before {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    border-radius: 50%;
    bottom: 12px;
    left: 46px;
    border: 2px solid white;
    background-color: rgba(234, 84, 85, 1);
    z-index: 6;
}
.chat_status_waiting:before {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    border-radius: 50%;
    bottom: 12px;
    left: 46px;
    border: 2px solid white;
    background-color: rgba(246, 144, 176, 1);
    z-index: 6;
}

.chat_list li > .name p {
    color: #393939;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}
.chat_list li > .name span {
    color: #82868B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat_list .active > .name p {
    color: white;
}

.chat_list .active > .name span {
    color: white;
}

.chat_list li > .chat_info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 4px;
}

.chat_list li > .chat_info .chat_time {
    color: #BABFC7;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
}

.chat_list .active > .chat_info .chat_time {
    color: white;
}

.chat_list li > .chat_info .chat_messages_count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 50%;
    background-color: rgba(246, 144, 176, 1);
}
.chat_list li > .chat_info .chat_messages_unreaded {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 50%;
    background-color:#7e72f4;
}

.chat_user_lists {
    overflow-y: auto !important;
    height: calc(100% - 69px);
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-top: 18px;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        background: transparent;
    }
}