.welcome_main_avatars_bar {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media screen and (max-width: 1024px) {
        overflow-x: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        justify-content: left;
        padding: 0 30px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        gap: 13px;
    }

    li {
        cursor: pointer;
        position: relative;

        &.unreaded {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: -3px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #EA5455;
                z-index: 3;
            }
        }

        &.online {
            .name {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 10px;
                    right: -3px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: rgb(40, 199, 111);
                    z-index: 3;
                }
            }
        }
        
        .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            border: 4px solid #FFF;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
            overflow: hidden;
            margin-bottom: 2px;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
            background-color: rgb(115, 103, 240);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            p {
                color: #fff;
                text-transform: capitalize;
                font-size: 24px;
            }


        }

        .name {
            color: #393939;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 18px;
            text-transform: capitalize;
        }

        &.selected {
            .avatar {
                border: 4px solid #7367F0;
            }
        }
    }
}
.welcome_main_avatars_bar::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}